import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import EventHome from './views/event-pages/EventHome'
//import '@coreui/coreui-pro/dist/css/coreui.min.css'


// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const AashwasanHomeLayout = React.lazy(() => import('./layout/AashwasanHomeLayout'))

const Donations = React.lazy(() => import('./views/payments/Donation'))
const ClientReport = React.lazy(() => import('./layout/DefaultLayout'))
//Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))

const ClientRegistrationForm = React.lazy(() =>
  import('./views/pages/SelfRegistration/ClientRegistrationForm')
)

const RegistrationComplete = React.lazy(() =>
  import('./views/pages/SelfRegistration/pages/RegistrationComplete')
)

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />

            <Route path="*" name="Home" element={<DefaultLayout />} />
            <Route path="/" name="Home" element={<DefaultLayout />} />
            <Route path="/events" name="Event Home" element={<EventHome />} />
            <Route exact path="/ClientRegistrationForm/:clientCode" name="Event Home" element={<ClientRegistrationForm />} />
            <Route exact path="/registrationComplete" name="" element={<RegistrationComplete />} />

            <Route exact path="/payment" name="Event Home" element={<Donations />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
